/***
 * spinning icon animation
 */
export const animeIconSpin = (elemId) => {
  let elem = document.getElementById(elemId);
  elem.getElementsByClassName("svg-inline--fa")[0].classList.toggle("fa-pulse");
}
/***
 * smooth scroll down to element animation
 */
export function scrollSmooth(elem) {
  const el = document.getElementById(elem);
  if (el) {
    el.scrollIntoView({behavior: "smooth"})
  }
}
/***
 * smooth rotate element when scrolling animation
 */
export function ScrollRotate(elem, speed) {
  const el = document.getElementById(elem);
  if (+speed < 1) {
    speed = 1;
  }
  if (el) {
    el.style.transform = "rotate(" + window.pageYOffset /  speed + "deg)";
  }
}
