<template>
  <section class="section has-padding-large">
    <div class="container">
      <h2 class="stretch-heading power">
        <span class="salt-span power">Power</span><br>Of Experience.
      </h2>
      <div class="columns">
        <!-- left side -->
        <div class="column">
          <div class="px-6">
            <div class="tabs is-boxed is-centered mb-0">
              <ul class="tabs-box">
                <li :class="chkActive(0)">
                  <a data-tab="0" class="tab-link has-text-dark" @click="toggleTab">
                    Students
                  </a>
                </li>
                <li :class="chkActive(1)">
                  <a data-tab="1" class="tab-link has-text-dark" @click="toggleTab">
                    Business
                  </a>
                </li>
              </ul>
            </div>
            <div class="tabs-content is-relative">
              <div v-if="curTab === 0" class="tab-pane">
                <ul class="power-list" role="list">
                  <li class="power-list-item">
                    Expanded career interests through exposure
                  </li>
                  <li class="power-list-item">
                    Learning how talents &amp; aptitudes fit into the world of work
                  </li>
                  <li class="power-list-item">
                    Increased curiosity &amp; academic engagement
                  </li>
                  <li class="power-list-item">
                    Deepened knowledge of occupation of interest
                  </li>
                  <li class="power-list-item">
                    Understanding what a day in the life looks like
                  </li>
                  <li class="power-list-item">
                    Improved academic pathway selection
                  </li>
                  <li class="power-list-item">
                    2x as likely to be placed in related first job
                  </li>
                  <li class="power-list-item">
                    Career pathway related training
                  </li>
                  <li class="power-list-item">
                    Resume enhancement
                  </li>
                </ul>
                <div class="pattern-shadow" />
              </div>
              <div v-if="curTab === 1" class="tab-pane w-tab-pane">
                <ul role="list" class="power-list w-list-unstyled">
                  <li class="power-list-item">
                    Expand talent capacity to fill high demand jobs
                  </li>
                  <li class="power-list-item">
                    Affect life trajectory of large groups of students
                  </li>
                  <li class="power-list-item">
                    Brand business with thousands of students, staff &amp; parents
                  </li>
                  <li class="power-list-item">
                    Increase capacity in the occupational talent pipeline
                  </li>
                  <li class="power-list-item">
                    Transfer knowledge to next generation workforce
                  </li>
                  <li class="power-list-item">
                    Improved social impact
                  </li>
                  <li class="power-list-item">
                    Close skills gaps
                  </li>
                  <li class="power-list-item">
                    Enhance essential skills
                  </li>
                  <li class="power-list-item">
                    2x as likely to hire interned student
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- right side -->
        <div class="column">
          <div class="px-6">
            <div class="activities-block is-relative">
              <div class="pattern-shadow" />
              <h3 class="activities-learning">Career Connected Activities</h3>
              <h4>Career Exploration</h4>
              <ul class="activities-list" role="list">
                <li class="activities-list-item">Class Mentorship<br></li>
                <li class="activities-list-item">Site Tours</li>
                <li class="activities-list-item">Career Class Presentation</li>
              </ul>
              <h4>Occupational Investigation</h4>
              <ul class="activities-list" role="list">
                <li class="activities-list-item">Capstone Mentorship<br></li>
                <li class="activities-list-item">Informational Interview</li>
                <li class="activities-list-item">Project Mentorship<br></li>
              </ul>
              <h4>Career Pathway Training</h4>
              <ul class="activities-list" role="list">
                <li class="activities-list-item">Industry Sponsored Projects</li>
                <li class="activities-list-item">Internships</li>
                <li class="activities-list-item">Youth Apprenticeships<br></li>
              </ul>
            </div>
          </div>
        </div>
        <!-- end -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PowerOfExperience",
  data() {
    return {
      curTab: 0
    }
  },
  methods: {
    // check selected tab is active
    chkActive(data) {
      return (data == this.curTab) ? "is-active" : ""
    },
    toggleTab(e) {
      this.curTab = +e.currentTarget.dataset.tab;
    }
  }
}
</script>

<style lang="scss" scoped>
.activities-block {
  position: relative;
  margin-top: 140px;
  padding: 60px 40px 40px;
  border-style: solid;
  border-width: 3px;
  border-color: #323e48;
  border-radius: 20px;
  background-color: #e5e5e5;
}
.activities-list {
  margin-bottom: 20px;
}
.activities-list-item {
  font-size: 24px;
}
.tabs {
  padding-right: 18px;
  padding-left: 18px;
}
.tabs-content {
  background-color: #fff;
  border-bottom: 1px solid #dbdbdb;
  border-left: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  border-radius: 20px;
  overflow: visible;
}
.tab-link {
  background-color: #dddddd;
  border-top: 1px solid #dbdbdb !important;
  border-right: 1px solid #dbdbdb !important;
  border-left: 1px solid #dbdbdb !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  color: #222222;
  font-family: Bwstretch, sans-serif;
  font-size: 36px;
  line-height: 1.3;
  margin-right: 20px;
  padding: 9px 20px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
}
@media screen and (min-width: 1280px) {
  .activities-list {
    font-size: 30px;
  }
}
@media screen and (max-width: 991px) {
  .activities-block {
    margin-top: 40px;
  }
  .activities-list-item {
    font-size: 23px;
  }
}
@media screen and (max-width: 768px) {
  .activities-list {
    text-indent: -4px;
  }
  .activities-list-item {
    font-size: 22px;
  }
}
@media screen and (max-width: 479px) {
  .activities-list {
    padding-left: 20px;
    text-indent: -6px;
  }
  .activities-list-item {
    font-size: 19px;
  }
}
</style>
