<template>
  <div class="banner-menu-title business">
    <span class="salt-span">F</span>or <br>Busin<span class="salt-span">e</span>ss
  </div>
</template>

<script>
export default {
  name: "BannerBiz"
}
</script>
