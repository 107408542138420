<template>
  <div class="banner-menu-title">
    <span class="salt-span">F</span>or<br>
    Educa<span class="salt-span">t</span>ion
  </div>
</template>

<script>
export default {
  name: "BannerEdu"
}
</script>
